import isUndefined from 'lodash/isUndefined'
import isFunction from 'lodash/isFunction'

export default function (repository, initData = {}) {
  return {
    data () {
      return Object.assign(initData, {
        saving: false,
        loading: false,
        item: null,
        _repository: repository
      })
    },
    created () {
      if (this.isNew) {
        this.item = repository.createModel()
      } else {
        this.fetch()
      }
    },
    computed: {
      isNew: function () {
        return this.$route.params.id === 'new'
      }
    },
    methods: {
      fetch (silent = false) {
        if (!silent) {
          this.loading = true
        }

        return repository.get(this.$route.params.id).then(
          async (response) => {
            this.item = response
            if (!isUndefined(this.afterFetch) && isFunction(this.afterFetch)) {
              await this.afterFetch()
            }
            if (!silent) {
              this.loading = false
            }
          },
          error => {
            if (error) {
              if (!silent) {
                this.loading = false
              }
            }
          }
        )
      },
      save (params = null) {
        if (this.saving) return
        this.saving = true

        let errorHandler = (error) => {
          if (error) {
            this.saving = false
          }
        }

        let item = Object.assign({}, this.item)

        if (!isUndefined(this.beforeSave) && isFunction(this.beforeSave)) {
          item = this.beforeSave(item)
        }

        if (!isUndefined(repository.toDto) && isFunction(repository.toDto)) {
          item = repository.toDto(item)
        }

        if (this.isNew) {
          return repository.create(item).then(
            response => {
              this.saving = false
              this.item = response.body

              if (!isUndefined(this.afterFetch) && isFunction(this.afterFetch)) {
                this.afterFetch()
              }

              if (!params) {
                this.$router.replace({ params: { id: this.item.uuid || this.item.id }})
              } else {
                this.$router.replace({ name: params.name })
              }
            },
            errorHandler
          )
        }
        return repository.update(this.$route.params.id, item).then(
          response => {
            this.saving = false
            if (params) {
              this.$router.replace({name: params.name})
            }
            if ('clearCache' in repository) {
              repository.clearCache()
            }
          },
          errorHandler
        )
      }
    }
  }
}
