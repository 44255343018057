import api from '../utils/api'
import qs from 'qs'

export default {
  get (userId) {
    return api.get(`collabusers/${userId}`)
  },
  update (userId, user) {
    return api.patch(`collabusers/${userId}`, user)
  },
  getAll (params) {
    return api.get('collabusers',
      {
        params: params,
        paramsSerializer: function (params) {
          return qs.stringify(params, {encode: false})
        }

      })
  },
  documents (userId) {
    return api.get(`collabdocs/${userId}`).then(data => {
      return data
    })
  }
}
