<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <spinner v-show="loading"></spinner>

                <article class="tile is-child box" v-if="!loading && item">
                    <div>
                        <h1 class="title">Пользователь</h1>
                        <h2 class="subtitle">{{ item.auth.username }}</h2>
                    </div>
                    <div class="is-pulled-right">
                        <a v-can="'login_to_account Collab'" :href="loginToAccount">Войти в лк</a>
                    </div>
                    <div class="is-clearfix"></div>
                    <form class="block" @submit.prevent="save">

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Зарегистрирован</label>
                            </div>
                            <div class="control">
                                <span>{{ item.created_at | dateTime }}</span>
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Логин</label>
                            </div>
                            <div class="control">
                                <input class="input" v-model.trim="item.auth.username">
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Фио</label>
                            </div>
                            <div class="control">
                                <input class="input" v-model.trim="item.full_name">
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Medialog SID</label>
                            </div>
                            <div class="control">
                                <input class="input" type="text"
                                       v-model.trim="item.medialog_collab_id"
                                >
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Дата рождения</label>
                            </div>
                            <div class="control">
                                <datepicker class="input" placeholder="ГГГГ-MM-ДД"
                                            :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
                                            v-model="item.birthday"
                                ></datepicker>
                            </div>
                        </div>

                        <div v-if="item.organization" class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Организация</label>
                            </div>
                            <div class="control">
                                <input v-if="item.organization" class="input" type="text" disabled="disabled"
                                       v-model.trim="item.organization.title"
                                >
                            </div>
                        </div>
                        <fieldset class="box">
                            <legend class="label has-text-centered">Документы</legend>
                            <div>
                                <ol v-if="documents.length" class="list list__ol">
                                    <li v-for="(el, i) in documents"><a :href="'/files/collab-files/' + el.file_name">{{
                                        el.file_name }}</a></li>
                                </ol>
                            </div>
                        </fieldset>
                        <fieldset class="box" v-if="item.payment_type == 'PAYMENT_INDIVID_TYPE'">
                            <legend class="label has-text-centered">Платежные реквизиты</legend>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Паспорт. Серия номер</label>
                                </div>
                                <div class="control">
                                    <input v-if="!item.organization" class="input" type="text" disabled="disabled"
                                           v-model.trim="item.payment.passport_number"
                                    >
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Пасспорт. Дата выдачи</label>
                                </div>
                                <div class="control">
                                    <input v-if="!item.organization" class="input" type="text" disabled="disabled"
                                           v-model.trim="item.payment.passport_date"
                                    >
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Пасспорт. Кем выдан</label>
                                </div>
                                <div class="control">
                                    <input v-if="!item.organization" class="input" type="text" disabled="disabled"
                                           v-model.trim="item.payment.passport_who_gave"
                                    >
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Адрес</label>
                                </div>
                                <div class="control">
                                    <input v-if="!item.organization" class="input" type="text" disabled="disabled"
                                           v-model.trim="item.payment.passport_address"
                                    >
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">СНИЛС</label>
                                </div>
                                <div class="control">
                                    <input v-if="!item.organization" class="input" type="text" disabled="disabled"
                                           v-model.trim="item.payment.snils"
                                    >
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">БИК</label>
                                </div>
                                <div class="control">
                                    <input v-if="!item.organization" class="input" type="text" disabled="disabled"
                                           v-model.trim="item.payment.bank_bik"
                                    >
                                </div>
                            </div>

                            <div class="control is-horizontal">
                                <div class="control-label">
                                    <label class="label">Расчетный счет</label>
                                </div>
                                <div class="control">
                                    <input v-if="!item.organization" class="input" type="text" disabled="disabled"
                                           v-model.trim="item.payment.bank_account"
                                    >
                                </div>
                            </div>
                        </fieldset>
                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Роли</label>
                            </div>

                            <div class="control">
                                <ul>
                                    <li v-for="(label, role) in roles">
                                        <label class="checkbox">
                                            <input
                                                    type="checkbox"
                                                    v-model="item.auth.roles"
                                                    :value="role"
                                            >
                                            {{ label }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label"></label>
                            </div>
                            <div class="control is-grouped">
                                <div class="control">
                                <button class="button is-primary" :class="{'is-loading': saving}">Сохранить</button>
                                </div>
                                <div class="control">
                                    <router-link :to="{ name: 'CollabDoctor'}" class="button">Отменить</router-link>
                                </div>
                                <div class="control">
                                <button @click.prevent="toggle(item)" class="button is-danger">
                                    {{ !item.moderated ? 'Активировать':'Деактивировать'}}
                                </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '../../services/collaborators'
import auth from '../../services/auth'
// import { required } from 'vuelidate/lib/validators'
import doctorsService from '../../services/doctors'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import Datepicker from 'vue-bulma-datepicker'
import { LIST_ITEMS_LIMIT } from '../../consts'

export default {
  mixins: [createOrUpdateViewMixin(userService, {roles: auth.collabRoles})],
  components: {
    Datepicker
  },
  data: function () {
    return {
      documents: []
    }
  },
  beforeRouteEnter (to, from, next) {
    let doctors = doctorsService.getAll({limit: LIST_ITEMS_LIMIT})

    Promise.all([doctors]).then(
      (values) => {
        next(vm => {
          vm.doctors = values[0].doctors
        })
      },
      () => {
        next(false)
      }
    )
  },
  methods: {
    afterFetch () {
      this.documents = userService.documents(this.item.uuid).then(documents => {
        this.documents = documents
      })
    },
    toggle: function (user) {
      if (user.moderated) {
        user.moderated = false
      } else {
        user.moderated = true
      }
      userService.update(user.uuid, user).then(() => {
      })
    }
  },
  computed: {
    loginToAccount: function () {
      return '/users/collab/switch/' + this.item.uuid
    }
  }
}
</script>
